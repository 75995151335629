import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'

const CategoriesList = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          categories {
            name
            slug
          }
        }
      }
      allMdx(sort: {order: DESC, fields: [frontmatter___date]}, limit: 1000) {
        pageCategories: group(field: frontmatter___categories) {
          fieldValue
        }
      }
    }
  `)

  const siteCategories = data.site.siteMetadata.categories;

  let categories = [];

  siteCategories.forEach(siteCategory => {
    if (data.allMdx.pageCategories.find(postCategory => postCategory.fieldValue === siteCategory.slug)) {
      categories.push(
        <li key={siteCategory.slug}>
          <Link to={`/category/${siteCategory.slug}/`} className="link link_text">
            {siteCategory.name}
          </Link>
        </li>
      )
    }
  });

  return (
    categories.length > 0 ? <ul>{categories}</ul> : <p>カテゴリーはまだありません</p>
  )
}

export default CategoriesList;
