import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import PostList from './postList.js'
import SimplePost from './simplePost.js'

const ResentPost = ({ type }) => {
  const data = useStaticQuery(graphql`
    query ResentPost{
      allMdx(sort: { order: DESC, fields: [frontmatter___date] } limit: 3) {
        edges {
          node {
            id
            frontmatter {
              date
              path
              title
              categories
              featuredImage {
                childImageSharp {
                  fluid(maxWidth: 678) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                  square: fluid(maxWidth: 128) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return type === 'postlist' ? <PostList posts={data.allMdx.edges} col="3" heading="3" /> : <SimplePost posts={data.allMdx.edges} />
}

export default ResentPost
