import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { changeTheme } from '../../state/app.js'
import './header.scss'

const Header = ({ isDark, offDark, dispatch, index }) => {
  const logo = (
    <Link to="/" className="site-name__link">
      <img src="/img/common/logo_main.svg" alt="UWEB" className="site-name__logo site-name__logo_main" aria-hidden="true" />
      <img src="/img/common/logo_white.svg" alt="UWEB" className="site-name__logo site-name__logo_white" aria-hidden="true" />
      <span className="screen-reader-text" lang="en">Uweb</span>
    </Link>
  )

  let [ie, setIe] = useState(false);

  useEffect(() => {
    if (window.matchMedia('(prefers-color-scheme: dark)').matches && !offDark) {
      dispatch(changeTheme(true, false))
    }

    judgeIe();
  })

  const judgeIe = () => {
    if (window.matchMedia('screen\0').matches) {
      setIe(true);
    }
  }

  return (
    <React.Fragment>
      <Helmet>
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&amp;family=Noto+Sans:wght@400;700&amp;display=swap" rel="stylesheet" media="print" onload="this.media='all'" />
        <noscript>
          {`<link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&amp;family=Noto+Sans:wght@400;700&amp;display=swap" rel="stylesheet" />`}
        </noscript>
      </Helmet>
      {
        ie ?
          <div className="notice">
            <div className="notice__inner">
              <p className="notice__title">
                非対応ブラウザをお使いです
              </p>
              <p className="notice__text">
                お使いのブラウザでは、表示の崩れが発生し、正常な動作が行えません。<br />
                「Google Chrome」「Microsoft Edge」「Firefox」「Safari」<br />
                のようなブラウザの利用をおすすめします
              </p>
            </div>
          </div>
          : ''
      }
      <noscript className="notice"><p className="notice__inner">当ブログを快適にご覧いただくには、ブラウザでJavaScriptを有効にすることをおすすめします。</p></noscript>
      <Helmet
        bodyAttributes={{
          class: isDark ? 'is-dark' : ''
        }}
      >
      </Helmet>
      <header className="header" id="pagetop">
        <div className="header__inner">
          {index ? <h1 className="site-name">{logo}</h1> : <p className="site-name">{logo}</p>}
          <div className="change-theme">
            <button type="button" onClick={() => dispatch(changeTheme(!isDark, !offDark))} className="change-theme__toggle">
              <span className="screen-reader-text">{isDark ? 'ライト' : 'ダーク'}モードに切り替え</span>
            </button>
          </div>
        </div>
      </header>
    </React.Fragment>
  )
}

export default connect(state => ({
  isDark: state.app.isDark,
  offDark: state.app.offDark
}), null)(Header);
