import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import NoImg from './noImg.js'
import Icon from './icon.js'
import './simplePost.scss'

const SidebarPost = ({ posts }) =>
  <div className="simple-post">
    {
      posts.map(
        post =>
          <div className="simple-post__item" key={post.node.frontmatter.path}>
            <Link to={post.node.frontmatter.path} className="simple-post__link">
              <div className="simple-post__content">
                <p className="simple-post__title">{post.node.frontmatter.title}</p>
                <p className="simple-post__date">
                  <Icon type="edit" className="simple-post__icon" />
                  <time dateTime={post.node.frontmatter.date}>{post.node.frontmatter.date.replace(/-/g, '.')}</time>
                </p>
              </div>
              <figure className="simple-post__thumb-area">
                {
                  post.node.frontmatter.featuredImage !== null ? <Img style={{ position: '' }} className="simple-post__thumb" fluid={{ ...post.node.frontmatter.featuredImage.childImageSharp.square, aspectRatio: 1 }} /> : <NoImg style={{ position: '' }} className="simple-post__thumb" />
                }
              </figure>
            </Link>
          </div>
      )
    }
  </div>


export default SidebarPost;
