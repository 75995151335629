import React from 'react'
import './content.scss'

export const ContentItem = ({ children, section }) => (
  <React.Fragment>
    {
      section ? <section className="content__item">{children}</section> : <div className="content__item">{children}</div>
    }
  </React.Fragment>
);

export const ContentMain = ({ children }) => (
  <main className="content__main">{children}</main>
)

export const ContentLead = ({ children }) => (
  <div className="content__lead">{children}</div>
)

class Content extends React.Component {
  static Item = ContentItem;
  static Main = ContentMain;
  static Lead = ContentLead;

  render() {
    return (
      <div className={`content ${this.props.className ? this.props.className : ''}`}>{this.props.children}</div>
    )
  }
}

export default Content
