import React from 'react'
import { Link } from 'gatsby'
import Icon from './icon.js'
import CategoriesList from './categoriesList.js'
import ResentPost from './resentPost.js'
import './nav.scss'

class Nav extends React.Component {
  constructor(props) {
    super(props);

    this.state = { navOpen: false };

    this.navToggle = this.navToggle.bind(this);
    this.navClose = this.navClose.bind(this);
  }

  navToggle() {
    this.setState({ navOpen: !this.state.navOpen });
  }

  navClose() {
    if (this.state.navOpen) {
      this.setState({ navOpen: false });
    }
  }

  smoothScroll(e) {
    e.preventDefault();
    window.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  render() {
    return (
      <nav className="main-nav">
        <div className="main-nav__buttons">
          <Link to="/" className="main-nav__item" type="button" onClick={this.navClose}>
            <Icon type="home" className="main-nav__icon" />
            <span className="main-nav__text upper" lang="en">
              Top
            </span>
          </Link>
          <button className="main-nav__item main-nav__toggle" type="button" onClick={this.navToggle} aria-expanded={this.state.navOpen} aria-controls="js-main-nav">
            <span className="main-nav__text" lang="en">Menu</span>
          </button>
          <a href="#pagetop" className="main-nav__item" onClick={(e) => {
            this.navClose()
            this.smoothScroll(e)
          }}>
            <Icon type="angle_up" className="main-nav__icon" />
            <span className="main-nav__text" lang="en">
              Page top
            </span>
          </a>
        </div>
        <div className="main-nav__content sidebar" id="js-main-nav" aria-hidden={!this.state.navOpen}>
          <section className="sidebar__item">
            <h3 className="sidebar__title">カテゴリー</h3>
            <div className="sidebar__content">
              <CategoriesList />
            </div>
          </section>
          <section className="sidebar__item">
            <h3 className="sidebar__title">最新記事</h3>
            <div className="sidebar__content">
              <ResentPost />
            </div>
          </section>
          <section className="sidebar__item">
            <h3 className="sidebar__title">作ったよ</h3>
            <div className="sidebar__content banner">
              <a href="https://spice.u-web-nana.com/" target="_blank" rel="noopener noreferrer" className="banner__link">
                <img src="/img/common/banner_spice.png" alt="制作を便利にするツールのサイトSPICEを使ってみる" className="banner__img" />
                <span className="screen-reader-text">制作を便利にするツールのサイト<span lang="en">Spice</span>を使ってみる</span>
              </a>
            </div>
          </section>
        </div>
      </nav>
    )
  }
}

export default Nav;
