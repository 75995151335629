import React from 'react'
import Profile from '../parts/profile.js'
import CategoriesList from '../parts/categoriesList.js'
import ResentPost from '../parts/resentPost.js'
import Ad from '../parts/ad.js';
import './sidebar.scss'

const Sidebar = () => (
  <aside className="sidebar content__sidebar">
    <h2 className="screen-reader-text">サイドバー</h2>
    <Profile position="sidebar" />
    <div className="sidebar__item">
      <Ad format="rectangle" slot="9398998205" />
    </div>
    <section className="sidebar__item">
      <h3 className="sidebar__title">作ったよ</h3>
      <div className="sidebar__content banner">
        <a href="https://spice.u-web-nana.com/" target="_blank" rel="noopener noreferrer" className="banner__link">
          <img src="/img/common/banner_spice.png" alt="制作を便利にするツールのサイトSPICEを使ってみる" className="banner__img" width="600" height="500" />
          <span className="screen-reader-text">制作を便利にするツールのサイト<span lang="en">Spice</span>を使ってみる</span>
        </a>
      </div>
    </section>
    <section className="sidebar__item">
      <h3 className="sidebar__title">カテゴリー</h3>
      <div className="sidebar__content">
        <CategoriesList />
      </div>
    </section>
    <section className="sidebar__item">
      <h3 className="sidebar__title">最新記事</h3>
      <div className="sidebar__content">
        <ResentPost />
      </div>
    </section>
  </aside>
)

export default Sidebar;
