import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({
  pageType,
  title,
  description,
  image,
  pathname,
  article,
  categories,
  datePublished,
  dateModified
}) => (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultTitle,
            titleTemplate,
            defaultDescription,
            siteUrl,
            defaultImage,
          },
        },
      }) => {
        const seo = {
          title: title || defaultTitle,
          logo: `${siteUrl}/img/common/logo.png`,
          description: description || defaultDescription,
          image: `${siteUrl}${image || defaultImage}`,
          url: `${siteUrl}${pathname || '/'}`,
          categories: categories || '',
          datePublished: datePublished,
          dateModified: dateModified,
        }

        const twitterUsername = '@nana_winter_web'

        const sameAs = [
          '"https://twitter.com/nana_winter_web"',
          '"https://instagram.com/nana_winter_web"'
        ]

        return (
          <Helmet title={seo.title} titleTemplate={article ? titleTemplate : ''}>
            <html lang="ja" />
            <meta name="description" content={seo.description} />
            <meta name="image" content={seo.image} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            <meta property="og:type" content={article ? 'article' : 'website'} />
            {seo.title && <meta property="og:title" content={seo.title} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.image && <meta property="og:image" content={seo.image} />}
            <meta name="twitter:card" content="summary_large_image" />
            {twitterUsername && (
              <meta name="twitter:creator" content={twitterUsername} />
            )}
            {seo.title && <meta name="twitter:title" content={seo.title} />}
            {seo.description && (
              <meta name="twitter:description" content={seo.description} />
            )}
            {seo.image && <meta name="twitter:image" content={seo.image} />}
            <script type="application/ld+json">
              {`
              {
                "@context": "https://schema.org",
                "@graph": [
                  {
                    "@type": "Organization",
                    "@id": "${siteUrl}/#organization",
                    "url": "${siteUrl}/",
                    "name": "UWEB",
                    "logo": {
                      "@type": "ImageObject",
                      "url": "${seo.logo}"
                    },
                    "sameAs": [${sameAs}]
                  },
                  {
                    "@type": "WebSite",
                    "@id": "${siteUrl}/#website",
                    "url": "${siteUrl}/",
                    "name": "UWEB",
                    "publisher": {
                      "@id": "${siteUrl}/#organization"
                    }
                  },
                  {
                    "@type": "WebPage",
                    "@id": "${seo.url}#webpage",
                    "url": "${seo.url}",
                    "inLanguage": "ja",
                    "name": "${seo.title}",
                    "isPartOf": {
                      "@id": "${siteUrl}/#website"
                    },
                    "image": {
                      "@type": "ImageObject",
                      "@id": "${seo.url}#primaryimage",
                      "url": "${seo.image}",
                      "width": 1200,
                      "height": 630
                    },
                    ${!article ? `
                    "about": {
                      "@id": "${seo.url}#organization"
                    },
                    `: ''}
                    "primaryImageOfPage": {
                      "@id": "${seo.url}#primaryimage"
                    },
                    "description": "${seo.description}"
                    ${pageType === 'single' ? `,"datePublished": "${seo.datePublished}"
                      ${seo.dateModified ? `,"dateModified": "${seo.dateModified}"` : ''}
                      ` : ''}
                  },
                  ${pageType === 'single' ? `
                      {
                        "@type": "Article",
                        "@id": "${seo.url}#article",
                        "isPartOf": {
                          "@id": "${seo.url}#webpage"
                        },
                        "author": {
                          "@id": "${siteUrl}/#author"
                        },
                        "headline": "${seo.title}",
                        "datePublished": "${seo.datePublished}",
                        ${seo.dateModified ? `"dateModified": "${seo.dateModified}",` : ''}
                        "commentCount": 0,
                        "mainEntityOfPage": {
                          "@id": "${seo.url}#webpage"
                        },
                        "publisher": {
                          "@id": "${siteUrl}/#organization"
                        },
                        ${categories !== '' ? `
                        "articleSection": "${categories.map(category => category.name)}",
                        ` : ''}
                        "image": {
                          "@type": "ImageObject",
                          "@id": "${seo.url}#primaryimage",
                          "url": "${seo.image}",
                          "width": 1200,
                          "height": 630
                        }
                      },
                    ` : ''}
                  {
                    "@type": "Person",
                    "@id": "${siteUrl}/#author",
                    "name": "やまぐちなな",
                    "sameAs": [${sameAs}],
                    "image": {
                      "@type": "ImageObject",
                      "@id": "${siteUrl}/#personlogo",
                      "url": "${siteUrl}/img/common/profile.jpg",
                      "width": 96,
                      "height": 96,
                      "caption": "やまぐちなな"
                    }
                  }
                ]
              }
            `}
            </script>
          </Helmet>
        )
      }}
    />
  )

export default SEO

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
}

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
  article: false,
}

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl
        defaultImage: image
      }
    }
  }
`
