import React from 'react'

class Ad extends React.Component {
  constructor(props) {
    super(props);
    this.format = props.format || 'rectangle';
    this.state = {
      adSet: false
    }
  }

  componentDidMount() {
    setTimeout(function () {
      window.adsbygoogle = window.adsbygoogle || []
      window.adsbygoogle.push({})
    }, 100)

    this.setState({ adSet: true });

    const finishResize = () => {
      var timer = false;

      window.addEventListener('resize', () => {
        if (timer !== false) {
          clearTimeout(timer);
        }
        this.setState({ adSet: false });

        timer = setTimeout(() => {
          this.setState({ adSet: true })
        }, 200);
      })
    };
    finishResize();
  }

  render() {
    return (
      <React.Fragment>
        <p>スポンサーリンク</p>
        <ins
          style={{ display: 'block', margin: 0, width: '100%' }}
          className="adsbygoogle"
          data-ad-client="ca-pub-7889616773757227"
          data-ad-slot={this.props.slot}
          data-ad-format={this.format}
          data-full-width-responsive="true"
          key={this.props}
        ></ins>
      </React.Fragment>
    )
  }
}
export default Ad;
