import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Icon from './icon.js'
import './profile.scss'

const Profile = ({ className }) => {
  const SNS = {
    twitter: {
      name: 'Twitter',
      url: 'https://twitter.com/nana_winter_web'
    },
    instagram: {
      name: 'Instagram',
      url: 'https://instagram.com/nana_winter_web'
    },
    feedly: {
      name: 'Feedly',
      url: 'https://feedly.com/i/subscription/feed/https://u-web-nana.com/feed/index.xml'
    },
    github: {
      name: 'GitHub',
      url: 'https://github.com/nana-winter-web'
    },
    codepen: {
      name: 'CodePen',
      url: 'https://codepen.io/nana_winter_web'
    },
    mail: {
      name: 'お問い合わせ',
      url: '/contact/'
    }
  }

  const data = useStaticQuery(graphql`
    query {
      file: file(relativePath: { eq: "common/profile.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 684, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section className={`profile${className ? ' ' + className : ''}`}>
      <h3 className="screen-reader-text">著者について</h3>
      <p className="profile__name">やまぐちなな</p>
      <div className="profile__text">
        <p>
          誰にでも優しいWebサイトを増やしたい
        </p>
        <p className="profile__link-area">
          <Link to="/hello-world/" className="profile__link">詳しく見る</Link>
        </p>
        <figure className="profile__img">
          <Img fluid={data.file.childImageSharp.fluid} />
        </figure>
      </div>
      <ul className="sns-list">
        {
          Object.keys(SNS).map(key => (
            <li className="sns-list__item" key={key}>
              <a href={SNS[key].url} target="_blank" rel="noopener noreferrer" className={`sns-list__link sns-list__link_${key}`}>
                <Icon type={key} />
                <span className="screen-reader-text">{SNS[key].name}</span>
              </a>
            </li>
          ))
        }
      </ul>
    </section>
  )
}

export default Profile;
