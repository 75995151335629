import React from 'react'

const Icon = ({ className, type }) => {

  const icons = {
    add: (
      <path d="M37,23.286H23.286V37H18.714V23.286H5V18.714H18.714V5h4.571V18.714H37Z" transform="translate(-5 -5)" />
    ),
    angle_down: (
      <g transform="translate(-4 32) rotate(-90)">
        <path d="M19.76,28.24,16,32,0,16,16,0l3.76,3.76L7.547,16Z" transform="translate(6.24 4)" />
      </g>
    ),
    angle_left: (
      <path d="M27.76,9.76,24,6,8,22,24,38l3.76-3.76L15.547,22Z" transform="translate(-2 -6)" />
    ),
    angle_right: (
      <path d="M12.35,6,8.59,9.76,20.8,22,8.59,34.24,12.35,38l16-16Z" transform="translate(-2.59 -6)" />
    ),
    angle_up: (
      <g transform="translate(36) rotate(90)">
        <path d="M27.76,9.76,24,6,8,22,24,38l3.76-3.76L15.547,22Z" transform="translate(-2 -2)" />
      </g>
    ),
    autorenew: (
      <path d="M15.636,8.273v4.364l5.818-5.818L15.636,1V5.364A11.616,11.616,0,0,0,5.8,23.2l2.124-2.124A8.539,8.539,0,0,1,6.909,17,8.734,8.734,0,0,1,15.636,8.273ZM25.469,10.8l-2.124,2.124a8.713,8.713,0,0,1-7.709,12.8V21.364L9.818,27.182,15.636,33V28.636A11.616,11.616,0,0,0,25.469,10.8Z" transform="translate(0 -1)" />
    ),
    clipboard: (
      <path d="M21-10H16a4,4,0,0,0-4-4,4,4,0,0,0-4,4H3A3,3,0,0,0,0-7V15a3,3,0,0,0,3,3H21a3,3,0,0,0,3-3V-7A3,3,0,0,0,21-10Zm-9-1.5A1.5,1.5,0,0,1,13.5-10,1.5,1.5,0,0,1,12-8.5,1.5,1.5,0,0,1,10.5-10,1.5,1.5,0,0,1,12-11.5Zm9,26.125a.376.376,0,0,1-.375.375H3.375A.376.376,0,0,1,3,14.625V-6.625A.376.376,0,0,1,3.375-7H6v2.25A.752.752,0,0,0,6.75-4h10.5A.752.752,0,0,0,18-4.75V-7h2.625A.376.376,0,0,1,21-6.625Z" transform="translate(4 14)" />
    ),
    edit: (
      <path d="M3,28.332V35H9.666L29.325,15.338,22.659,8.672ZM34.48,10.183a1.77,1.77,0,0,0,0-2.506L30.321,3.517a1.77,1.77,0,0,0-2.506,0L24.561,6.77l6.666,6.666,3.253-3.253Z" transform="translate(-3 -2.998)" />
    ),
    folder: (
      <path d="M14.8,4H5.2A3.2,3.2,0,0,0,2.016,7.2L2,26.4a3.209,3.209,0,0,0,3.2,3.2H30.8A3.209,3.209,0,0,0,34,26.4v-16a3.209,3.209,0,0,0-3.2-3.2H18Z" transform="translate(-2 -1)" />
    ),
    home: (
      <path d="M14.8,30.2V20.6h6.4v9.6h8V17.4H34L18,3,2,17.4H6.8V30.2Z" transform="translate(-2 -1)" />
    ),
    mail: (
      <path d="M34,7.2A3.209,3.209,0,0,0,30.8,4H5.2A3.209,3.209,0,0,0,2,7.2V26.4a3.209,3.209,0,0,0,3.2,3.2H30.8A3.209,3.209,0,0,0,34,26.4Zm-3.2,0L18,15.184,5.2,7.2Zm0,19.2H5.2v-16l12.8,8,12.8-8Z" transform="translate(-2 -1)" />
    ),
    refresh: (
      <path d="M31.29,8.7A16,16,0,1,0,35.45,24H31.29A11.994,11.994,0,1,1,19.99,8a11.83,11.83,0,0,1,8.44,3.56L21.99,18h14V4Z" transform="translate(-4.01 -4)" />
    ),
    sync: (
      <path d="M15.636,5.364V1L9.818,6.818l5.818,5.818V8.273A8.734,8.734,0,0,1,24.364,17a8.539,8.539,0,0,1-1.018,4.073L25.469,23.2A11.616,11.616,0,0,0,15.636,5.364Zm0,20.364A8.734,8.734,0,0,1,6.909,17a8.539,8.539,0,0,1,1.018-4.073L5.8,10.8a11.616,11.616,0,0,0,9.833,17.833V33l5.818-5.818-5.818-5.818Z" transform="translate(0 -1)" />
    ),
    quote_left: (
      <path d="M29-26H21a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3h5v4a4,4,0,0,1-4,4h-.5A1.5,1.5,0,0,0,20-2.5v3A1.5,1.5,0,0,0,21.5,2H22A10,10,0,0,0,32-8V-23A3,3,0,0,0,29-26ZM11-26H3a3,3,0,0,0-3,3v8a3,3,0,0,0,3,3H8v4A4,4,0,0,1,4-4H3.5A1.5,1.5,0,0,0,2-2.5v3A1.5,1.5,0,0,0,3.5,2H4A10,10,0,0,0,14-8V-23A3,3,0,0,0,11-26Z" transform="translate(0 28)" />
    ),
    done: (
      <path d="M13.582,24.873,5.945,17.236,3.4,19.782,13.582,29.964,35.4,8.145,32.855,5.6Z" transform="translate(-3.4 -2.6)" />
    ),
    open_in_new: (
      <path d="M31.444,31.444H6.556V6.556H19V3H6.556A3.555,3.555,0,0,0,3,6.556V31.444A3.555,3.555,0,0,0,6.556,35H31.444A3.566,3.566,0,0,0,35,31.444V19H31.444ZM22.556,3V6.556h6.382L11.462,24.031l2.507,2.507L31.444,9.062v6.382H35V3Z" transform="translate(-3 -3)" />
    ),
    // brand
    codepen: (
      <path d="M31.393-4.018l-14.625-9.75a1.389,1.389,0,0,0-1.536,0L.607-4.018A1.4,1.4,0,0,0,0-2.876v9.75A1.4,1.4,0,0,0,.607,8.017l14.625,9.751a1.389,1.389,0,0,0,1.536,0L31.393,8.017A1.4,1.4,0,0,0,32,6.874v-9.75A1.4,1.4,0,0,0,31.393-4.018ZM17.375-10.054,28.143-2.876,23.339.339,17.375-3.644Zm-2.75,0v6.411L8.661.339l-4.8-3.214ZM2.75-.3,6.2,2,2.75,4.3ZM14.625,14.053,3.857,6.874l4.8-3.214,5.964,3.982ZM16,5.249,11.143,2,16-1.251,20.857,2Zm1.375,8.8V7.642L23.339,3.66l4.8,3.214ZM29.25,4.3,25.8,2,29.25-.3Z" transform="translate(0 14)" />
    ),
    facebook: (
      <path d="M13.487-8.687H16.5v-5.088A40.255,40.255,0,0,0,12.112-14c-4.344,0-7.319,2.65-7.319,7.519V-2H0V3.688H4.794V18h5.875V3.688h4.6L16-2H10.669V-5.919C10.669-7.562,11.125-8.687,13.487-8.687Z" transform="translate(8 14)" />
    ),
    feedly: (
      <path d="M30.945,13.587,18.371,1.013a3.493,3.493,0,0,0-4.923,0L.973,13.488a3.493,3.493,0,0,0,0,4.923L8.33,25.768c1.353,1.353,2.364,2.46,4.277,2.46h6.8c1.919,0,2.924-1.107,4.277-2.463l7.261-7.258A3.493,3.493,0,0,0,30.945,13.587ZM12.488,21.453h-.617a1.036,1.036,0,0,1-.64-.259l-1.225-1.225a.368.368,0,0,1,0-.521l5.639-5.627a.374.374,0,0,1,.521,0l1.9,1.9a.365.365,0,0,1,0,.518l-4.948,4.952A1.04,1.04,0,0,1,12.488,21.453Zm5.6,2.239-1.241,1.241a1.02,1.02,0,0,1-.64.259H15.6a1.017,1.017,0,0,1-.64-.259l-1.225-1.225a.365.365,0,0,1,0-.518l1.919-1.919a.368.368,0,0,1,.521,0l1.9,1.9a.368.368,0,0,1,.013.5Zm.022-14.877L9.417,17.493a1.046,1.046,0,0,1-.64.259H8.17a1.03,1.03,0,0,1-.64-.259L6.305,16.268a.368.368,0,0,1,0-.521L15.687,6.4a.365.365,0,0,1,.518,0l1.919,1.9a.374.374,0,0,1-.016.505Z" transform="translate(0.042 2.002)" />
    ),
    github: (
      <path d="M10.7,11.373c0-.148-.168-.252-.361-.232-.187,0-.335.1-.335.232,0,.148.148.252.361.232C10.555,11.6,10.7,11.5,10.7,11.373ZM8.7,11.082c-.045.129.084.277.277.316a.3.3,0,0,0,.4-.129c.039-.129-.084-.277-.277-.335A.331.331,0,0,0,8.7,11.082Zm2.852-.11c-.187.045-.316.168-.3.316.019.129.187.213.381.168s.316-.168.3-.3S11.736,10.953,11.548,10.973ZM15.794-13.75A15.47,15.47,0,0,0,0,1.992,16.175,16.175,0,0,0,10.935,17.424c.826.148,1.116-.361,1.116-.781,0-.4-.019-2.606-.019-3.961,0,0-4.516.968-5.465-1.923,0,0-.735-1.877-1.794-2.361,0,0-1.477-1.013.1-.994a3.406,3.406,0,0,1,2.49,1.665,3.41,3.41,0,0,0,4.7,1.348A3.586,3.586,0,0,1,13.1,8.244c-3.606-.4-7.245-.923-7.245-7.129a4.89,4.89,0,0,1,1.523-3.8,6.1,6.1,0,0,1,.168-4.381C8.9-7.486,12-5.324,12-5.324a15.241,15.241,0,0,1,8.1,0s3.1-2.168,4.452-1.742a6.093,6.093,0,0,1,.168,4.381,5.015,5.015,0,0,1,1.665,3.8c0,6.226-3.8,6.723-7.406,7.129a3.815,3.815,0,0,1,1.1,2.994c0,2.174-.019,4.865-.019,5.394,0,.419.3.929,1.116.781A16.026,16.026,0,0,0,32,1.992C32-6.956,24.742-13.75,15.794-13.75ZM6.271,8.5c-.084.065-.065.213.045.335.1.1.252.148.335.065.084-.065.065-.213-.045-.335C6.5,8.463,6.355,8.418,6.271,8.5Zm-.7-.523c-.045.084.019.187.148.252A.192.192,0,0,0,6,8.185C6.045,8.1,5.981,8,5.852,7.934,5.723,7.9,5.619,7.915,5.574,7.979Zm2.09,2.3c-.1.084-.065.277.084.4.148.148.335.168.419.065.084-.084.045-.277-.084-.4C7.942,10.192,7.748,10.173,7.665,10.276Zm-.735-.948c-.1.065-.1.232,0,.381s.277.213.361.148a.294.294,0,0,0,0-.4C7.2,9.308,7.032,9.244,6.929,9.327Z" transform="translate(0 14.75)" />
    ),
    instagram: (
      <path d="M16-5.212a8.2,8.2,0,1,0,8.2,8.2A8.19,8.19,0,0,0,16-5.212ZM16,8.324a5.333,5.333,0,1,1,5.333-5.333A5.342,5.342,0,0,1,16,8.324ZM26.453-5.547A1.913,1.913,0,0,0,24.539-7.46a1.913,1.913,0,0,0-1.913,1.913,1.909,1.909,0,0,0,1.913,1.913A1.909,1.909,0,0,0,26.453-5.547Zm5.433,1.942a9.468,9.468,0,0,0-2.584-6.7,9.53,9.53,0,0,0-6.7-2.584c-2.641-.15-10.558-.15-13.2,0a9.516,9.516,0,0,0-6.7,2.577A9.5,9.5,0,0,0,.11-3.612C-.04-.971-.04,6.946.11,9.587a9.468,9.468,0,0,0,2.584,6.7,9.543,9.543,0,0,0,6.7,2.584c2.641.15,10.558.15,13.2,0a9.468,9.468,0,0,0,6.7-2.584,9.53,9.53,0,0,0,2.584-6.7C32.035,6.946,32.035-.964,31.885-3.605ZM28.473,12.421a5.4,5.4,0,0,1-3.041,3.041c-2.106.835-7.1.642-9.43.642s-7.332.186-9.43-.642A5.4,5.4,0,0,1,3.53,12.421c-.835-2.106-.642-7.1-.642-9.43S2.7-4.341,3.53-6.439A5.4,5.4,0,0,1,6.571-9.481c2.106-.835,7.1-.642,9.43-.642s7.332-.186,9.43.642a5.4,5.4,0,0,1,3.041,3.041c.835,2.106.642,7.1.642,9.43S29.308,10.323,28.473,12.421Z" transform="translate(0.002 13.005)" />
    ),
    line: (
      <path d="M19.436-.7A.226.226,0,0,0,19.2-.921h-.814a.226.226,0,0,0-.229.229V2.321L15.829-.821a.215.215,0,0,0-.186-.1h-.807a.226.226,0,0,0-.229.229V4.386a.226.226,0,0,0,.229.229h.814a.226.226,0,0,0,.229-.229V1.371l2.329,3.143a.236.236,0,0,0,.186.093h.814a.226.226,0,0,0,.229-.229ZM13.579-.929h-.814a.226.226,0,0,0-.229.229V4.379a.226.226,0,0,0,.229.229h.814a.226.226,0,0,0,.229-.229V-.7A.23.23,0,0,0,13.579-.929ZM11.614,3.329H9.393V-.7a.226.226,0,0,0-.229-.229H8.35A.226.226,0,0,0,8.121-.7V4.379a.21.21,0,0,0,.064.157.241.241,0,0,0,.157.064h3.264a.226.226,0,0,0,.229-.229V3.557A.229.229,0,0,0,11.614,3.329ZM23.721-.929H20.457a.23.23,0,0,0-.229.229V4.379a.23.23,0,0,0,.229.229h3.264a.226.226,0,0,0,.229-.229V3.564a.226.226,0,0,0-.229-.229H21.5V2.479h2.221a.226.226,0,0,0,.229-.229V1.429a.226.226,0,0,0-.229-.229H21.5V.343h2.221A.226.226,0,0,0,23.95.114V-.7A.24.24,0,0,0,23.721-.929ZM32-7.164A5.818,5.818,0,0,0,26.214-13H5.836A5.823,5.823,0,0,0,0-7.214V13.164A5.823,5.823,0,0,0,5.786,19H26.164A5.818,5.818,0,0,0,32,13.214ZM27.6,1.593a8.439,8.439,0,0,1-2.493,5.821,37.648,37.648,0,0,1-9.157,6.764c-1.243.521-1.057-.336-1.007-.629.307-1.836.464-2.336-.9-2.629C8.443,10.179,4.3,6.264,4.3,1.593c0-5.214,5.229-9.457,11.65-9.457S27.6-3.621,27.6,1.593Z" transform="translate(0 13)" />
    ),
    pocket: (
      <path d="M29.114-12H2.9A2.93,2.93,0,0,0,0-9.1V.557a16,16,0,1,0,32,0V-9.1A2.876,2.876,0,0,0,29.114-12ZM17.543,7.179a2.175,2.175,0,0,1-3.029,0C6.393-.6,6.307-.329,6.307-1.621A2.2,2.2,0,0,1,8.5-3.814c1.214,0,1.15.271,7.514,6.379,6.471-6.207,6.329-6.379,7.536-6.379a2.2,2.2,0,0,1,2.193,2.193C25.743-.35,25.536-.5,17.543,7.179Z" transform="translate(0 14)" />
    ),
    twitter: (
      <path d="M28.711-6.02A14.1,14.1,0,0,0,32-9.411,13.148,13.148,0,0,1,28.223-8.4a6.541,6.541,0,0,0,2.883-3.614,12.912,12.912,0,0,1-4.162,1.584A6.543,6.543,0,0,0,22.152-12.5a6.555,6.555,0,0,0-6.558,6.558,7.4,7.4,0,0,0,.162,1.5A18.642,18.642,0,0,1,2.233-11.3,6.509,6.509,0,0,0,1.34-7.99,6.554,6.554,0,0,0,4.264-2.528,6.6,6.6,0,0,1,1.3-3.36v.081A6.559,6.559,0,0,0,6.558,3.157a6.935,6.935,0,0,1-1.726.223,8.266,8.266,0,0,1-1.239-.1A6.57,6.57,0,0,0,9.726,7.828a13.135,13.135,0,0,1-8.142,2.8A13.573,13.573,0,0,1,0,10.548a18.533,18.533,0,0,0,10.071,2.944c12.061,0,18.66-9.99,18.66-18.66C28.731-5.452,28.731-5.736,28.711-6.02Z" transform="translate(0 15.497)" />
    ),
  }

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" style={{ width: 1 + 'em', height: 1 + 'em', fill: 'currentColor', verticalAlign: '-0.125em' }} className={className ? className : ''} aria-hidden="true">
      {icons[type]}
      <path d="M0,0H32V32H0Z" fill="none" />
    </svg>
  )
}

export default Icon;
