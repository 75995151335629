import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import Header from './header.js'
import Footer from './footer.js'
import Content from './content.js'
import Sidebar from './sidebar.js'
import Nav from '../parts/nav.js'
import './layout.scss'

const Layout = ({ pickup, sidebar, children, breadcrumb, pageTitle, className }) => {
  const pickupItem = {
    about: {
      title: 'UWEBについて',
      url: '/hello-world/',
    },
    gutenberg: {
      title: 'Gutenbergカスタマイズ',
      url: '/gutenberg-original-block-01/',
    },
    vw: {
      title: 'vw / vhの話',
      url: '/yes-no-vw-unit/',
    },
    contact: {
      title: 'お問い合わせ',
      url: '/contact/',
    },
  }

  const pickupImg = useStaticQuery(graphql`
    query {
      about: file(relativePath: { eq: "pickup/about.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 273, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      contact: file(relativePath: { eq: "pickup/contact.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 273, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      vw: file(relativePath: { eq: "pickup/vw.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 273, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      gutenberg: file(relativePath: { eq: "pickup/gutenberg.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 273, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <React.Fragment>
      <Header index={pageTitle && pageTitle.type === 'index' ? true : false} />
      {pageTitle ?
        <div className="page-title">
          <div className="page-title__content">
            {
              pageTitle.type === 'index' ?
                <React.Fragment>
                  <p className="page-title__text page-title__text_main">誰にでも優しい<br className="mobile-only" />Webサイトを増やしたい</p>
                  <p className="page-title__text page-title__text_sub">アクセシビリティをもっと身近に</p>
                </React.Fragment>
                : (
                  <React.Fragment>
                    {pageTitle.subTitle ? <span className="page-title__text page-title__text_sub" lang={pageTitle.subTitle.lang ? pageTitle.subTitle.lang : null}>{pageTitle.subTitle.text}</span> : ''}
                    <h1 className="page-title__text page-title__text_main" lang={pageTitle.mainTitle.lang ? pageTitle.mainTitle.lang : null}>{pageTitle.mainTitle.text}</h1>
                  </React.Fragment>
                )
            }
          </div>
        </div>
        : ''}
      {pickup ?
        <section className="pickup">
          <h2 className="screen-reader-text">おすすめ記事</h2>
          <ul className="pickup__list">
            {
              Object.keys(pickupItem).map(
                item => (
                  <li className="pickup__item" key={pickupItem[item].url}>
                    <Link to={pickupItem[item].url} className="pickup__link">
                      <span className="screen-reader-text">{pickupItem[item].title}</span>
                      <Img fluid={pickupImg[item].childImageSharp.fluid} className="pickup__img" />
                    </Link>
                  </li>
                )
              )
            }
          </ul>
        </section>
        : ''}
      {
        sidebar ? (
          <Content className="content_clm">
            <Content.Main>{children}</Content.Main>
            <Sidebar />
          </Content>
        ) : (
            <Content className={className}>{children}</Content>
          )
      }
      <Nav />
      <Footer breadcrumb={breadcrumb} />
    </React.Fragment>
  )
}
export default Layout;
