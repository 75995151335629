import React from 'react'
import { Link } from 'gatsby'
import './footer.scss'
import Profile from '../parts/profile.js'
import CategoriesList from '../parts/categoriesList.js'
import ResentPost from '../parts/resentPost.js'

const Footer = ({ breadcrumb }) => {
  let breadcrumbCount = 2;

  return (
    <footer className="footer">
      <div className="footer__main">
        <div className="footer__inner">
          {
            breadcrumb ? (
              <ol className="breadcrumb" itemScope itemType="https://schema.org/BreadcrumbList">
                <li className="breadcrumb__item" itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                  <Link to="/" className="upper breadcrumb__link" itemProp="item" itemType="https://schema.org/Thing">
                    <span itemProp="name">Top</span>
                  </Link>
                  <meta itemProp="position" content="1" />
                </li>
                {
                  breadcrumb.map(breadcrumb => (
                    <li className="breadcrumb__item" key={breadcrumb.url} itemProp="itemListElement" itemScope itemType="https://schema.org/ListItem">
                      <Link to={breadcrumb.url} className="breadcrumb__link" itemProp="item" itemType="https://schema.org/Thing">
                        <span itemProp="name">{breadcrumb.name}</span>
                      </Link>
                      <meta itemProp="position" content={breadcrumbCount++} />
                    </li>
                  ))
                }
              </ol>
            ) : ''
          }

          <div className="footer__content sidebar">
            <Profile className="footer__item" />
            <section className="footer__item">
              <h3 className="sidebar__title">カテゴリー</h3>
              <div className="sidebar__content">
                <CategoriesList />
              </div>
            </section>
            <section className="footer__item">
              <h3 className="sidebar__title">最新記事</h3>
              <div className="sidebar__content">
                <ResentPost />
              </div>
            </section>
          </div>
        </div>
      </div>
      <div className="footer__sub">
        <div className="footer__inner">
          <ul className="sub-nav">
            <li className="sub-nav__item">
              <Link to="/contact/" className="sub-nav__link link link_text">お問い合わせ</Link>
            </li>
            <li className="sub-nav__item">
              <Link to="/privacy/" className="sub-nav__link link link_text">プライバシーポリシー</Link>
            </li>
          </ul>
          <p className="copyright">
            &copy; 2020 <Link to="/" className="upper copyright__link link link_text">Uweb</Link> by Nana Yamaguchi
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
