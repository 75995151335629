import React from 'react'
import { Link, useStaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'
import NoImg from './noImg.js'
import Icon from './icon.js'
import './postList.scss'

const PostLink = ({ posts, col, heading }) => {
  const colClass = parseFloat(col) === 2 ? 'post_two-col' : parseFloat(col) === 3 ? 'post_three-col' : '';

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          categories {
            name
            slug
          }
        }
      }
      allMdx(sort: {order: DESC, fields: [frontmatter___date]}, limit: 1000) {
        pageCategories: group(field: frontmatter___categories) {
          fieldValue
        }
      }
    }
  `)

  const siteCategories = data.site.siteMetadata.categories;

  let categoriesHasPost = {};

  siteCategories.forEach(siteCategory => {
    if (data.allMdx.pageCategories.find(postCategory => postCategory.fieldValue === siteCategory.slug)) {
      categoriesHasPost[siteCategory.slug] = {
        name: siteCategory.name,
        slug: siteCategory.slug
      }
    }
  });

  return (
    <div className={`post ${colClass}`}>
      {
        posts.map(post => (
          <article className="post__item" key={post.node.id}>
            <Link to={post.node.frontmatter.path} className="post__link">
              <div className="post__content">
                {heading === '3' ? <h3 className="post__title">{post.node.frontmatter.title}</h3> : <h2 className="post__title">{post.node.frontmatter.title}</h2>}
                <div className="post__meta">
                  <p className="post__date">
                    <Icon type="edit" className="post__icon" />
                    <time dateTime={post.node.frontmatter.date}>{post.node.frontmatter.date.replace(/-/g, '.')}</time>
                  </p>
                  {
                    // post.node.frontmatter.modified > post.node.frontmatter.date ? <p className="post__date">
                    //   <Icon type="sync" />
                    //   <span className="screen-reader-text">更新日</span>
                    //   <time dateTime={post.node.frontmatter.modified}>{post.node.frontmatter.modified.replace(/-/g, '.')}</time>
                    // </p> : ''
                  }
                  {
                    (() => {
                      let categoryList = [];

                      if (post.node.frontmatter.categories) {
                        post.node.frontmatter.categories.forEach((item) => {

                          if (categoriesHasPost[item]) {
                            categoryList.push(<li key={item} className="categories-list__item">{categoriesHasPost[item].name}</li>)
                          }
                        })
                      }
                      return categoryList.length > 0 ?
                        <div className="post__categories">
                          <Icon type="folder" className="post__icon" />
                          <ul className="categories-list">{categoryList}</ul>
                        </div>
                        : ''
                    })()
                  }
                </div>
              </div>
              <figure className="post__thumb-area">
                {
                  post.node.frontmatter.featuredImage !== null ? <Img style={{ position: '' }} className="post__thumb" fluid={post.node.frontmatter.featuredImage.childImageSharp.fluid} /> : <NoImg style={{ position: '' }} className="post__thumb" />
                }
              </figure>
            </Link>
          </article>
        ))
      }
    </div>
  )
}

export default PostLink
